<template>
  <div class="row process-props-container py-5">
    <h2 class="text-center montserrat-header text-white mb-5">Our Process</h2>
    
    <div class="props-content mx-auto d-flex flex-column flex-lg-row justify-content-evenly">
      <div v-for="process in  processProps" :key="process.id" class="col-12 col-lg-3 d-flex flex-column justify-content-start align-items-center">
        <img :src="process.imageUrl" class="process-icon"/>
        <h2 class="text-center montserrat-bold text-white py-3">{{ process.title }}</h2>
        <p class="text-center montserrat-regular text-white mx-auto" style="max-width: 300px;">{{ process.text }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ProcessCard',
  props: ['processProps']
}
</script>

<style lang="scss">
.process-props-container {
  background-image: url('../assets/process-background-mobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


  @media(min-width: 992px) {
    background-image: url('../assets/process-background-desktop.png');
  }

  .props-content {
    max-width: 600px;
    
    @media(min-width: 992px) {
      max-width: 1400px;
    }
  }

  .blaked-btn {
    max-width: 300px;
  }

  .process-icon {
    width: 100px;
    height: 100px;

    @media(min-width: 992px) {
      width: 150px;
      height: 150px;
    }
  }
}

</style>