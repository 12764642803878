<template>
  <!-- Hero -->
  <FAQHero />

  <!-- Accordion -->
  <div class="row faq-container py-5">
    <div class="accordion d-flex flex-wrap justify-content-evenly mx-auto pb-5" id="accordionExample" style="max-width: 1400px;">
      <div v-for="(faq, index) in faqArray" :key="faq.id" class="accordion-item col-12">
        <h2 class="accordion-header" :id="'headingOne' + index">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapseOne' + index" aria-expanded="true" :aria-controls="'collapseOne' + index">
            <img src="../assets/cbd-leaf-small.png" class="cbd-bulletpoint"/>{{ faq.question }}
          </button>
        </h2>
        <div :id="'collapseOne'+ index" class="accordion-collapse collapse show" :aria-labelledby="'headingOne' + index" data-bs-parent="#accordionExample">
          <div class="accordion-body">

            <div v-if="!faq.hasImage">
              <div class="col-12 px-lg-5">
                <p>{{ faq.a1 }}</p>
                <p>{{ faq.a2 }}</p>
              </div>
            </div>
            <div v-else class="d-flex">
              <div class="col-4 d-none d-lg-block">
                <img :src="faq.image" class="img-fluid" />
              </div>
              <div class="col-12 col-lg-7 d-flex flex-column justify-content-center px-lg-5">
                <p>{{ faq.a1 }}</p>
                <p>{{ faq.a2 }}</p>
                <img :src="faq.image" class="img-fluid d-block d-lg-none" />
                <p>{{ faq.a3 }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Our Process -->
  <ProcessCard :processProps="processProps" />

  <!-- Learn More -->
  <div class="row learn-more d-flex flex-column align-items-center justify-content-center py-5">
    <h2 class="text-green montserrat-header text-center">Learn More</h2>
    <p class="text-green montserrat-regular text-center mx-auto py-3" style="max-width: 450px;">Download our Labsheets to see our CBD Certifications and learn more about our Ultra Broad Spectrum CBD. </p>
    <a href="/get-blaked-labsheets.pdf" download class="blaked-btn py-2 mb-4" style="max-width: 300px;">Download Labsheets</a>
  </div>

  <!-- FAQ Drawers -->
  <FAQDrawers :faqs="faqs" />
  
</template>
<script>
import FAQHero from '../components/FAQHero.vue'
import FAQDrawers from '../components/FAQDrawers.vue'
import ProcessCard from '../components/ProcessCard.vue'

import { faqArray } from '../data/faq.js';
import processProps from '../data/processProps.js';
import faqs from '../data/faq'

export default {
  name: 'FAQ',
  // props: ['faqArray', 'processProps'],
  data() {
    return {
      faqArray,
      processProps,
      faqs
    }
  },

  components: {
    FAQHero,
    ProcessCard,
    FAQDrawers
  }
}
</script>

<style lang="scss" scoped>
.cbd-bulletpoint {
  height: 40px;
  margin-right: 10px;
}

a {
  text-decoration: none;
  color: white;
}

.accordion-item {
    background-color: unset;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1.5px solid #11484f; 
  }
  
  .accordion-button:not(.collapsed), .accordion-button {
    color: #11484f;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.26px;
    line-height: 32px;
    text-align: left;
    background-color: unset;
    box-shadow: none;

    @media(min-width: 992px) {
      font-size: 22px;
    }
  }

  .accordion-body {
    color: #000000;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.23px;
    line-height: 24px;
    text-align: left;
  }

  .accordion-button:focus {
    border: none;
    box-shadow: none;
  }

  .accordion-button::after {
    background-image: url("../assets/green-plus.svg");
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../assets/green-minus.svg");
  }

  .learn-more {
    background-color: #eef9f8;
  }
</style>
