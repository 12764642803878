<template>
  <div class="row hero">
    <div class="col-12 col-lg-6 offset-lg-1 d-flex flex-column py-5 mt-5 justify-content-end justify-content-lg-center align-items-start" style="position: relative; overflow: hidden;">
      <h2 class="text-green montserrat-header text-start">What is CBD?</h2>
      <h2 class="text-green montserrat-header d-none d-lg-block text-start">Why should I take Cannabidiol?</h2>
      <p class="montserrat-regular text-green py-3 text-start" style="font-weight: 500;">CBD is cannabidiol, a compound found naturally in hemp plants. In the past few years CBD has become one of the most buzzed-about topics in the natural wellness space because it may support a sense of peace and well-being in humans. Don’t worry, it’s not intoxicating, so you can add CBD to your everyday routine without experiencing a high.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQHero'
}
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("../assets/faq-hero-mobile.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 76vh;

  @media(min-width: 992px) {
    background-image: url("../assets/faq-hero-desktop.png");
    height: 80vh;
  }

  .logo {
    width: 260px;

    @media(min-width: 992px) {
      width: 480px;
    }
  }

  p {
    max-width: 500px;
  }
}


</style>