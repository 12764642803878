export default [
  {
    id: 0,
    title: 'Step 1',
    text: 'Make the dough, add mix-ins, and bake',
    imageUrl: require('../assets/oven-white.svg'),
  },
  {
    id: 1,
    title: 'Step 2',
    text: 'Measure CBD using a medical syringe to ensure accuracy',
    imageUrl: require('../assets/blaked-dropper.png'),
  },
  {
    id: 2,
    title: 'Step 3',
    text: 'Inject CBD! Your cookies are ready to eat :)',
    imageUrl: require('../assets/cbd-leaf-white-outline.svg'),
  }
];
